<template>
  <main class="kb-main" id="kb-hrdcloud" @click="closeToggles(toggles)">
    <lxp-main-header :show-breadcrumb="false" :show-custom-title="false" :show-excel-download="false" >
    </lxp-main-header>

    <div class="main-content main-component" style="width:1680px;" >
            <div class="intro-section" style="margin-bottom: 10px;">


              <div class="intro-column intro-my">
                <div class="my-info">
                  <div class="avatar" style="overflow:unset;"><img :src="bgImgUrl" /></div>
                </div>
                <div class="my-info" style="padding-top:10px;">
                  <h4 class="name">가장 인상 깊었던<br>기업문화 콘텐츠에 <br> 좋아요를 눌러주세요!</h4><br>
                  <p class="team text-center">투표기간 <br> 11.18(월) ~ 11.22(금)</p>
                </div>
              </div>
              <CommonBanner
                  :banner-code=bannerCode
              />
            </div>
<!--        <div class="notice-box">-->
<!--          <div  class="notice-item">-->
<!--            <div class="notice-title">-->
<!--              HR부(인재개발) 제작 콘텐츠와 ECHO · 크리에이터 제작 콘텐츠 중 각각 1개씩 가장 인상 깊었던 콘텐츠에 좋아요를 눌러주세요.-->
<!--              </div>-->
<!--          </div>-->
<!--        </div>-->
        <div  style="display:inline-flex; width:100%;" class="mt-2" >
            <template v-if="tabMenu<=0" >
              <div class="pt-5" style="width:100%; border-right:1px solid var(--kb-light-silver); padding-right:10px">
                <article class="content-section">
                  <header class="section-header" style="display:flex; justify-content : center;">
                  <h3 class="title" style="font-size: 22px">[2024년 에코(ECHO) 영상 콘텐츠]</h3>
                </header>
                  <div class="guide-table">
                    <div class="kb-table kb-table-striped">
                      <table>
                        <tbody>
                        <tr>
                          <td style="text-align : center;">
                            <span class="td-text" > 아래 영상중 가장 인상 깊었던 콘텐츠에 좋아요를 눌러주세요!</span>
                          </td>
                        </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </article>
                <BoardReadOnly
                    :board-id="boardId[0]"
                />
              </div>
            </template>

<!--            <template v-if="tabMenu<=0"  >-->
<!--              <div class="pt-5" style="width:50%; border-left:1px solid var(&#45;&#45;kb-light-silver); padding-left:10px">-->
<!--                <article class="content-section">-->
<!--                  <header class="section-header">-->
<!--                    <h4 class="title" style="font-size: 22px">[직원참여 제작]</h4>-->
<!--                  </header>-->
<!--                  <div class="guide-table">-->
<!--                    <div class="kb-table kb-table-striped">-->
<!--                      <table>-->
<!--                        <tbody>-->
<!--                        <tr>-->
<!--                          <td>-->
<!--                            <span class="td-text">아래 영상중 가장 인상 깊었던 콘텐츠에 좋아요를 눌러주세요!</span>-->
<!--                          </td>-->
<!--                        </tr>-->
<!--                        </tbody>-->
<!--                      </table>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </article>-->
<!--              <BoardReadOnly-->
<!--                  :board-id="boardId[1]"-->
<!--              />-->
<!--              </div>-->
<!--            </template>-->
      </div>

    </div>

  </main>
</template>

<script>
import {useStore} from 'vuex';
import {computed, reactive, ref} from 'vue';
import {
  closeToggles,
  getDcdOptions,
} from '@/assets/js/ui.init';
import {
  initProgressBar,
  timestampToDateFormat
} from '@/assets/js/util';
import {
  banerExpsrLocCdDcds,
} from '@/assets/js/modules/baner/common-baner';
// import ProfileImg from '@/components/common/ProfileImg';
import CommonBanner from '@/components/common/CommonBanner';
// import {
//   ACT_GET_HRDCLOUD_BPCC_LIST
// } from '@/store/modules/prep/prep';
// import {Pagination} from "swiper";
import LxpMainHeader from '@/components/common/LxpMainHeader';
import BoardReadOnly from '@/components/board/BoardReadOnly'
// import HrdCloudRow from '@/components/hrdcloud/HrdCloudRow';
// import LoadingDiv from '@/components/common/LoadingDiv';
const hrdLoading = ref(false);


export default {
  name: 'CccMain',
  components: {
    BoardReadOnly,
    CommonBanner,
    // Swiper,
    // SwiperSlide,
    LxpMainHeader,
    // LoadingDiv,
    // HrdCloudRow,
    // ProfileImg,
  },
  setup() {

    const popups = ref([]);

    initProgressBar();
    const boardId = reactive([
      'ccc-echo-vote'
      // ,
      // 'ccc-vote',
      // 'ccc-vote-create',
    ]);

    const store = useStore();
    const lochref = ref(location.href);
    const bannerCode = ref(banerExpsrLocCdDcds.ccc);
    const cloudMapModal = ref(false);
    const items = ref([]);
    // const mainList = ref([
    //   { name: 'ccc', title: '투표소개 영상\n', params: {}, isLoading: true, items:[] }
    // ]);
    const isLoading = ref(false);

    const session = computed(() => store.state.auth.session);
    const sortTypeDcd = computed(() => getDcdOptions('2073'));

    // 순서를 변경할때 getMainList의 idx값도 같이 바꿔줘야함

    // const currentTs = ref(new Date().getTime());
    const menu = ref(1);
    const myLikeTotalCnt = ref('0');

    const hrds = ref([]);
    const categories = ref([]);
    const bgImgUrl = require('@/assets/lxp/images/main/kiki_cc.png');
    // const tubePaging = reactive({
    //   pageNo: 1,
    //   pageSize: 4,
    //   totalCount: 0
    // });

    const params = ref({});
    const tabMenu = ref(0);
    const toggles = reactive({
      sortTyCdDcd: false,
      lvl1Toggle: false
    });
    // 지금 나에게 필요한 콘텐츠, 따끈따끈 신규 콘텐츠, 나의 최근 학습 콘텐츠, 최근 직무 분야 인기 콘텐츠, 최근 직무외 분야 인기 콘텐츠
    // const getMainList = async () => {
    //   let sumgo = await store.dispatch(`prep/${ACT_GET_HRDCLOUD_BPCC_LIST}`, {...tubePaging});
    //   setMainItems(mainList, sumgo, 0);
    // };

    // getListFunc(`learn/${ACT_GET_SUMGO_LEARNING_LIVE_LIST}`, {}, items_live, null, () => {
    // });

    // getMainList();

    return {
      isLoading, session, sortTypeDcd, hrds,   cloudMapModal, lochref,
      tabMenu, params, categories,
      // mainList,
      toggles, closeToggles,
      popups,
      items,
      // items_tube,
      // items_board,
      // items_live,
      // weekly_board_first,
      // weekly_board_second,
      menu,
      myLikeTotalCnt,
      // board_first,
      // board_second,
      timestampToDateFormat,
      hrdLoading,
      bannerCode,
      boardId,
      bgImgUrl,

    }
  }
};
</script>
<!--<style scoped>-->
<!--.notice-box {border: 1px solid var(&#45;&#45;kb-border-color); border-radius: 12px; margin-top:-2em; margin-bottom:1em;}-->
<!--.notice-item {display:flex;align-items:center;height:68px;justify-content:center;}-->
<!--.notice-title {overflow:hidden;text-overflow:ellipsis;white-space:nowrap;max-width:1290px;}-->
<!--</style>-->